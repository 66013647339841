/**
  Global overwrite of MatFormField for special cases
*/

// TODO: merge similar styles

// overwrite 180px of mat-form-field
.collapp-ctrl-01-dropdown-list-radio mat-form-field {
  .mat-mdc-form-field-infix {
    width: auto;
    max-width: unset;
  }

  .mat-mdc-form-field-prefix {
    min-width: 50%;
    max-width: 50%;
  }

  /* stylelint-disable selector-no-qualifying-type */
  &.long-prefix .mat-mdc-form-field-prefix {
    width: auto;
    max-width: unset;
    max-width: calc(100% - 24px);
  }
  /* stylelint-enable selector-no-qualifying-type */
}

.collapp-ctrl02-date-range-picker__week-button .mat-mdc-button-focus-overlay {
  display: none;
  opacity: 0;
}

// ==========================================
// Angular Material styles overwrite
// ==========================================
// TODO MIGRATION: we need !important
.mat-mdc-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-mdc-text-field-wrapper,
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  // padding: 0 0 1.25em 0;
  // padding: 0;
  /* stylelint-disable-next-line declaration-no-important */
  background: none !important;
  padding-left: 0;
}

.mat-mdc-form-field-focus-overlay {
  display: none;
}

.mat-mdc-list-item {
  cursor: pointer;
  display: block;
  height: 48px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  padding: 0;
  font-size: 16px;

  &:not(.mat-mdc-list-item-interactive) {
    /* stylelint-disable-next-line declaration-no-important */
    cursor: pointer !important;
  }
}

.mat-mdc-tab,
.mat-mdc-tab-link {
  // letter spacing
  --mdc-dialog-supporting-text-tracking: normal;
  --mat-tab-header-label-text-tracking: normal;
}

.mat-mdc-paginator-page-size {
  /* stylelint-disable-next-line declaration-no-important */
  align-items: center !important;
}

.mat-mdc-paginator-page-size-label {
  color: #0000008a;
}

.mat-mdc-paginator-page-size-select {
  /* stylelint-disable-next-line declaration-no-important */
  margin: 6px 4px 0 !important;
  /* stylelint-disable-next-line declaration-no-important */
  width: 56px !important;

  .mat-mdc-text-field-wrapper {
    /* stylelint-disable-next-line declaration-no-important */
    padding: 0.4em 0 0.7em !important;

    // background: none;
  }

  .mat-mdc-form-field-infix {
    width: auto;

    // padding: 0;
    min-height: 10px;
  }

  .mdc-notched-outline__trailing,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__leading {
    /* stylelint-disable-next-line */
    border: none !important;
  }
}

.mat-mdc-dialog-surface {
  padding: 24px;
}

.mat-expansion-panel-header-title {
  /* stylelint-disable-next-line declaration-no-important */
  flex-basis: unset !important;
}

.table2-sidepanel .mat-mdc-tab {
  // letter spacing
  --mdc-dialog-supporting-text-tracking: normal;

  flex-grow: 1;
  min-width: 50px;
  max-width: 90px;
  /* stylelint-disable-next-line declaration-no-important */
  height: 60px !important;
  margin-right: 1px;
  padding: 0 2px;
  /* stylelint-disable-next-line declaration-no-important */
  opacity: .45 !important;
  /* stylelint-disable-next-line declaration-no-important */
  background-color: #ffffff !important;
  /* stylelint-disable-next-line declaration-no-important */
  color: #000000 !important;
  font-size: 8px;
  font-weight: 500;
  margin-left: 4px;

  &:first-child {
    margin-left: 1px;
  }

  &.mdc-tab--active {
    background-color: #ffffff;
    /* stylelint-disable-next-line declaration-no-important */
    opacity: 1 !important;
  }

  /* stylelint-disable-next-line */
  &:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
    /* stylelint-disable-next-line declaration-no-important */
    color: #000000 !important;
  }
}

// TODO MIGRATION: don't know if it needs to be global. Changed made for wp-planning sidebar/planning
collapp-table-colors-selector .mat-mdc-radio-button,
collapp-totals-selector,
.project-filter__dialog,
.collapp-ctrl-01-dropdown-list-radio__dialog,
.collapp-column-selector-menu {
  // should not be overwritten... the default-padding is for ergonomic
  .mdc-radio {
    /* stylelint-disable-next-line declaration-no-important */
    padding: 0 !important;
  }

  .mdc-form-field {
    width: 100%;
  }

  /* stylelint-disable-next-line */
  .mdc-form-field > label {
    padding-left: 8px;
    width: 100%;
  }

  .mdc-label {
    white-space: nowrap;
  }
}

// doesn't work in the component - due to encapsulation
.table2-working-days, .table2-planning-helper working-days-selector {
  .mdc-checkbox {
    --mat-checkbox-touch-target-display: none;
    --mdc-checkbox-state-layer-size: 14px;

    padding: 0;
    margin-right: 8px;
  }
}

.sidebar-content {
  .mat-mdc-list-item {
    padding: 0;
  }

  .mdc-list-item__primary-text {
    display: flex;
  }

  .mdc-list-item__content {
    padding: 0 10px;
  }
}

html .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-line-height: 24px;
}

collapp-totals-selector .mat-mdc-checkbox,
.collapp-column-selector-menu .mat-mdc-checkbox {
  .mdc-checkbox {
    --mat-checkbox-touch-target-display: none;
    --mdc-checkbox-state-layer-size: 16px;

    padding: 0;
    margin-right: 8px;
    height: 16px;

    .mdc-checkbox__ripple,
    .mat-mdc-checkbox-ripple,
    .mdc-checkbox__background {
      width: 16px;
      height: 16px;
    }
  }

  .mdc-label {
    white-space: nowrap;
  }
}

.mat-mdc-icon-button {
  /* stylelint-disable-next-line declaration-no-important */
  --mdc-icon-button-state-layer-size: 40px !important;

  /* stylelint-disable-next-line declaration-no-important */
  padding: 0 !important;
  display:flex;
  justify-content: center;
  align-items: center;

  .mdc-button-ripple,
  .mat-mdc-focus-indicator,
  .mat-mdc-button-persistent-ripple {
    // same as directive noRipple, but problem with "Button extending MatButton"
    display:none;
  }
}


.collapp-ctrl-01-dropdown-list-radio,
.planning-category-select,
collapp-skill-filter .skill-filter,
collapp-project-filter .project-filter,
.unit-planning .unit-planning__filters-resource {
  // display:flex;
  // align-items:flex-end;
  height:45px;
  min-width: 120px;

  .mat-mcd-form-field-prefix {
    max-width: 100%;
  }

  .mat-mdc-form-field-flex,
  .mat-mdc-form-field-icon-suffix  {
    align-self: flex-end;
  }

  .mat-mdc-form-field-flex {
    justify-content: space-between;
    align-items:flex-end;
  }

  .mat-mdc-form-field-infix {
    --mat-form-field-container-height: 45px;

    position: absolute;
  }

  .mat-icon {
    vertical-align: bottom;
    display: inline-block;
    margin-bottom: -4px;
  }
}

collapp-skill-filter .skill-filter .mat-mdc-form-field-icon-suffix {
  position: absolute;
  right: 0;
}

/* stylelint-disable-next-line */
.planning-category-select .ng-select.ng-select-multiple .ng-select-container.ng-has-value {
  width: 120px;
}

:root {
  --mdc-dialog-supporting-text-tracking: normal;
  --mdc-text-button-label-text-tracking: normal;
  --mdc-list-list-item-label-text-tracking: normal;
  --mat-divider-color: rgb(0 0 0 / 42%)
  --mat-divider-width: 2px;
  --mat-table-row-item-container-height: 47px;
  --mat-form-field-subscript-text-line-height: 14px;

  .mat-mdc-row {
    height: var(--mat-table-row-item-container-height, 47px);;
  }

  .mat-mdc-dialog-title {
    padding-left: 0;
  }

  .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
    padding: 0;
  }

  .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)::after {
    margin-left: 3px;
  }

  .mat-mdc-form-field-error {
    margin-left: -3px;
  }

  .mat-mdc-dialog-container .mdc-dialog__content {
    // default text color
    --mdc-dialog-supporting-text-color: #000000de;

    // letter spacing
    --mdc-dialog-supporting-text-tracking: normal;

    /* stylelint-disable-next-line declaration-no-important */
    margin: 0 -24px;
    padding: 0 24px;
    max-height: 65vh;
    overflow: auto;

    mat-hint {
      color: rgb(0 0 0 / 60%);
    }

    mat-radio-button {
      margin: 0;
    }
  }
}

.collapp-dialog__row-close {
  /* stylelint-disable-next-line */
  #wp-workflow-status-transition-data-conclusion .ng-value-container {
    padding: 0;
  }

  .mdc-text-field--filled .mdc-floating-label--float-above {
    transform: translateY(-106%) scale(1);
  }
}

.collapp-dialog__question-container {
  .mat-mdc-floating-label {
    padding-bottom: 5px;
  }

  .mdc-text-field {
    padding-right: 0;
  }
}

.cdk-overlay-container .mdc-menu-surface {
  min-width: 60px;
  padding: 0;
  border-radius: 4px;

  .mat-mdc-option {
    // or change the variable --mat-option-label-text-size
    font-size: 12px;
  }
}

// input with datepicker
.planning-node-form .planning-node-form__date-input,
.user-unit-assignment__date-picker-wrapper {
  .mat-mdc-text-field-wrapper {
    height: 50px;
  }

  .mat-mdc-form-field-icon-suffix {
    display: flex;
  }
}

.unit-defects__add-wrapper {
  .mat-mdc-form-field-infix {
    display: flex;
    justify-content: center;
  }

  .mdc-icon-button {
    display: flex;
    align-items: center;
    justify-content: center;

    .mat-icon-no-color {
      padding-right: 0;
    }
  }
}

.form-section .ng-invalid {
  .mdc-floating-label, .mat-mdc-form-field-hint {
    color: var(--mdc-filled-text-field-error-label-text-color);
  }
}

.mat-mdc-unelevated-button {
  --mdc-filled-button-label-text-tracking: normal;
}

.new-project-init {
  .mat-mdc-radio-button {
    height: 30px;
    margin: 0;
    border: 0;
  }
}

/* stylelint-disable-next-line */
#new-project-data-file {
  width: 100%;
  height: 65px;

  /* stylelint-disable-next-line */
  .mat-mdc-form-field-infix {
    justify-content: center;
  }
}

.db-projects-actions--secondary {
  .mat-mdc-button>.mat-icon {
    height: 25px;
    width: 25px;
    font-feature-settings: "liga";
    /* stylelint-disable-next-line declaration-no-important */
    font-size: 24px !important;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    margin-left: 5px;
  }
}

/* stylelint-disable-next-line */
.mdc-dialog__container .unit-contract-list-item .ng-select.ng-select-single .ng-select-container .ng-clear-wrapper {
  align-self: unset;
  bottom: 2px;
}

/* stylelint-disable-next-line */
.mdc-dialog__container .unit-contract-list-item .ng-select.ng-select-single .ng-select-container .ng-arrow-wrapper {
  height: 20px;
}

.unit-contract-list-item__fte {
  overflow: hidden;

  .mat-mdc-form-field-infix {
    min-height: 53px;
  }
}

.unit-contract-list-item__form {
  .collapp-people-select {
    padding-top: 10px;
  }

  .ng-arrow-wrapper, .ng-clear-wrapper {
    /* stylelint-disable-next-line declaration-no-important */
    border-top: 0 !important;
    line-height: 15px;
    height: 15px;
  }

  .mat-form-field--no-underline .mdc-text-field--filled .mdc-line-ripple::before {
    /* stylelint-disable-next-line declaration-no-important */
    border: 0 !important;
  }
}

.new-user-dialog,
.edit-user-dialog {
  .mat-mdc-text-field-wrapper {
    padding-right: 0;
    padding-left: 0;
  }
}

.edit-unit-dialog .edit-unit-dialog__unit-coordinator .collapp-people-select {
  margin-top: 2px;
}

.edit-unit-dialog .edit-unit-dialog__belongs-to .mat-mdc-text-field-wrapper{
  height: 57px;
}

.edit-unit-dialog .unit-defects__add-wrapper mat-label {
  margin-left: 36px;
}

.edit-unit-dialog .unit-defects__add-wrapper .mat-focused mat-label {
  margin-left: 0;
}

.edit-unit-dialog__export-settings {
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 20px;
  }

  .mat-mdc-form-field {
    --mat-form-field-container-text-line-height: normal;
  }

  /* stylelint-disable-next-line */
  .form-section__body > * {
    margin-bottom: 1em;
  }
}

// color of label, sometimes blue
.mat-focused {
/* stylelint-disable-next-line selector-max-specificity */
  .mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label,
  .mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label--float-above {
    color: $collapp-grey;

    /* stylelint-disable-next-line selector-max-specificity */
    ::after {
      color: $collapp-brand-accent;
    }
  }
}

// color of label, sometimes blue
.mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label,
.mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label--float-above {
  color: var(--mdc-filled-text-field-label-text-color);
}

.user-unit-assignment-list-item  .mat-mdc-text-field-wrapper {
  min-height: 55px;
}

.user-unit-assignment-list-item,
.planning-node-form__date-input,
.collapp-dialog__calendar-input,
.planning-view__sidepanel-dates,
.edit-work-package-form__schedule,
.collapp-dialog__new-estimated-end-date {
  mat-datepicker {
    display: none;
  }

  .mat-mdc-form-field-icon-suffix {
      --mdc-icon-button-icon-size: 16px;

      display: flex;
      flex-direction: row;
      align-items: end;
      height: 100%;
      padding-bottom: 5px;

      .mat-icon-button, .mat-icon, .mdc-icon-button {
        display: flex;
        align-items: end;
        height: 23px;
        width: 23px;
        font-size: 18px;
        scale: 0.9;
      }
    }

    .mat-mdc-form-field-flex {
      height: 100%;
    }
}

.collapp-dialog__adaption {
  .mat-mdc-form-field-infix {
    justify-content: end;
  }
}

.planning-node-form__date-input {
  .mat-mdc-form-field-icon-suffix {
    padding-bottom: 20px;
  }
}

.mat-tree-node, .mat-nested-tree-node {
  --mdc-filled-text-field-label-text-size: 14px;
  --mat-form-field-container-text-size: 14px;

  .mat-mdc-form-field-flex {
    height: 55px;
  }

  .mat-mdc-form-field-icon-suffix {
    top: 5px;
  }

  .mat-mdc-form-field-hint-wrapper {
    font-size: 75%;
    margin-top: -5px;
  }

  .mat-mdc-form-field-hint-end, .mat-form-field-hint-end {
    color: #0000008a;
  }

  .mdc-text-field__input {
    font-size: 14px;
  }

  .mat-mdc-form-field-subscript-wrapper {
    position: absolute;
    bottom: -22px;
  }

  .mat-mdc-form-field {
    position: relative;
  }

  .mat-mdc-text-field-wrapper {
    position: relative;
  }

  .mat-mdc-form-field[fxflex] .mat-mdc-form-field-infix {
    justify-content: space-around;
  }
}

.mat-pseudo-checkbox[aria-hidden='true'] {
  display: none;
}
