
@use '@angular/material' as mat;

/* stylelint-disable-next-line */
@include mat.core();

// https://v15.material.angular.io/guide/typography#typography-configs-and-theming

// ==========================================
// Angular Material Theme Overrides
// ==========================================

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$primary: mat.define-palette($collapp-brand-grey-palette);
$accent: mat.define-palette($collapp-brand-accent-palette);

// The warn palette is optional (defaults to red).
$warn: mat.define-palette($collapp-brand-error-palette);

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$custom-typography: mat.define-typography-config(
  $font-family: $primary-font,
); 

  // TODO MIGRATION , in define-typography-config but doesn't exist anymore
  // $input: mat.define-typography-level(0.875, 1.125, 400)

// Create the theme object (a Sass map containing all of the palettes).
$theme: mat.define-light-theme((
 color: (
   primary: $primary,
   accent: $accent,
   warn: $warn,
 ),
 typography: $custom-typography,
 density: 0,
));

@include mat.all-component-themes($theme);
